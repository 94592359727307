<template>
    <div>
        <div class="container">
            <div class="handle-box">
                <label class="el-form-item__label"><el-input v-model="query.info_name" placeholder="就诊人" class="handle-input mr10"></el-input></label>
                <label class="el-form-item__label">
                    <el-select v-model="query.confirmed" clearable placeholder="请选择状态" class="handle-select mr10">
                    <el-option key="1" label="待确认" :value="0"></el-option>
                    <el-option key="2" label="已确认" :value="1"></el-option>
                    <el-option key="3" label="全部" value=""></el-option>
                </el-select>
                </label>
                <label class="el-form-item__label">创建日期：
                    <el-date-picker @change="handleDate"
                    v-model="date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </label>
                <el-button type="primary" icon="el-icon-search" class="ml10" @click="handleSearch">搜索</el-button>
                <el-button type="info" icon="el-icon-copy-document" class="ml10" @click="handleReset">重置</el-button>
            </div>
            <el-table :data="tableData" class="table" ref="multipleTable" header-cell-class-name="table-header" @row-click="handleEdit">
                <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
                <el-table-column align="center" prop="code" label="服务单号" width="180"></el-table-column>
                <el-table-column align="center" prop="name" label="就诊人"></el-table-column>
                <el-table-column align="center" prop="sex" label="性别"></el-table-column>
                <el-table-column align="center" prop="age" label="年龄"></el-table-column>
                <el-table-column align="center" prop="medic_name" label="专家"></el-table-column>
                <!-- <el-table-column align="center" label="缴费状态" align="center">
                    <template #default="scope">
                        <el-tag v-if="scope.row.status===2" type="success">已缴费</el-tag>
                        <el-tag v-else-if="scope.row.status===3" type="info">已关闭</el-tag>
                        <el-tag v-else-if="scope.row.status===1">部分缴费</el-tag>
                        <el-tag v-else type="danger">待缴费</el-tag>
                    </template>
                </el-table-column> -->
                <el-table-column align="center" label="状态">
                    <template #default="scope">
                        <el-tag :type="scope.row.confirmed == 1 ? 'success' : 'danger' ">{{ scope.row.confirmed == 1 ? '已确认' : '待确认' }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="create_time" width="180" label="创建时间"></el-table-column>

                <el-table-column align="center" label="操作" width="180">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-edit" @click.stop="handleEdit(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    background
                    layout="total, prev, pager, next, sizes"
                    :current-page="query.page"
                    :page-size="query.limt"
                    :page-sizes="[10, 20, 50]"
                    :total="pageTotal"
                    @current-change="handlePageChange"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { confirmedList } from "../../api/index";
export default {
    name: "drugsList",
    data() {
        return {
            query: {
                confirmed: null,
                info_name: "",
                create_begin: '',
                create_end: '',
                page: 1,
                limt: 10
            },
            date: '',
            pageTotal: 0,   //总条数
            tableData: [],
           
        };
    },
    created() {
        let query = this.$route.query
        if(query&&query.serve_begin){
            this.query.create_begin = query.serve_begin
            this.query.create_end = query.serve_end
            this.query.confirmed = 0
        }
        this.getData();
    },
    methods: {
        getData() {
            confirmedList(this.query).then(res => {
                console.log(res);
                this.tableData = res.list;
                this.pageTotal = res.total;
            });
        },
        // 触发搜索按钮
        handleSearch() {
            this.query.page = 1
            this.getData();
        },
        // 重置
        handleReset(){
            this.query = {
                confirmed: null,
                info_name: "",
                create_begin: '',
                create_end: '',
                page: 1,
                limt: 10
            }
            this.getData();
        },

        // 编辑操作
        handleEdit(row){
            this.$router.push({path: '/drugsInfo',query:{id:row.id, status: row.status, confirmed: row.confirmed}})
        },

        // 时间选择
        handleDate(){
            if(this.date){
                const d = new Date(this.date[0])
                const d2 = new Date(this.date[1])
                this.query.create_begin = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate()
                this.query.create_end = d2.getFullYear() + '-' + (d2.getMonth()+1) + '-' + d2.getDate()
            }else{
                this.query.create_begin = ''
                this.query.create_end = ''
            } 
        },
       
        // 分页导航
        handlePageChange(val) {
            this.query.page = val
            this.getData();
        },
        handleSizeChange(val){
            this.query.page = 1
            this.query.limt = val
            this.getData();
        }
    }
};
</script>

<style scoped>
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.ml10{
    margin-left: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>

